<template>
  <svg
    viewBox="0 0 19 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1689 21C18.1689 21.7956 17.8529 22.5587 17.2903 23.1213C16.7277 23.6839 15.9646 24 15.1689 24H3.16895C2.3733 24 1.61023 23.6839 1.04762 23.1213C0.485016 22.5587 0.168945 21.7956 0.168945 21V3C0.168945 2.20435 0.485016 1.44129 1.04762 0.87868C1.61023 0.316071 2.3733 0 3.16895 0L11.4189 0L18.1689 6.75V21ZM3.16895 1.5C2.77112 1.5 2.38959 1.65804 2.10829 1.93934C1.82698 2.22064 1.66895 2.60218 1.66895 3V18L5.00495 14.664C5.12317 14.5461 5.27737 14.4709 5.44312 14.4506C5.60887 14.4302 5.77667 14.4657 5.91995 14.5515L9.16895 16.5L12.4044 11.97C12.4678 11.8815 12.5496 11.8078 12.6442 11.754C12.7389 11.7003 12.8441 11.6678 12.9526 11.6588C13.061 11.6498 13.1702 11.6645 13.2724 11.702C13.3746 11.7394 13.4674 11.7986 13.5444 11.8755L16.6689 15V6.75H13.6689C13.0722 6.75 12.4999 6.51295 12.078 6.09099C11.656 5.66903 11.4189 5.09674 11.4189 4.5V1.5H3.16895Z"
      fill="currentColor"
    />
  </svg>
</template>
